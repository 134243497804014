import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import claim from "../../images/claim.svg";

import "./MainRow.css";

const MainRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "renderA.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const { newsletterRef } = props;

  return (
    <div style={{ display: "grid" }}>
      <GatsbyImage
        image={getImage(data.file)}
        objectPosition="90% 50%"
        className="main-row-image"
        alt={data.file.name}
      />
      <div className="main-row-container">
        <div className="landing-page-limiter main-row-content">
          <img src={claim} className="hazay-logo" alt="Hazay Logo" />
          <div
            className="main-row-button"
            onClick={() =>
              newsletterRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          >
            <span
              style={{
                padding: "10px 20px",
                cursor: "pointer",
              }}
            >
              Dołącz do e-rewolucji
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainRow;
