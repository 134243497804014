import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Parallax } from "react-scroll-parallax";

import Container from "./Container";

import "./LandingPage.css";
import "./RangeRow.css";

const RangeRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      bike: file(relativePath: { eq: "renderC.png" }) {
        childImageSharp {
          gatsbyImageData(width: 480, layout: CONSTRAINED)
        }
      }
      marathon: file(relativePath: { eq: "NYmarathonroute.png" }) {
        childImageSharp {
          gatsbyImageData(width: 160, layout: CONSTRAINED)
        }
      }
      finishline: file(relativePath: { eq: "finishline.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Container containerStyle={{ backgroundColor: "#ffd64c" }}>
      <div className="details-range-row-container">
        <div className="range-row-first-block">
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              marginRight: "1rem",
            }}
          >
            <div>
              <h2 style={{ paddingBottom: "2rem" }}>Zasięg do 80km </h2>
            </div>
            <p>
              Elektryczny napęd zasilany jest z baterii o pojemności{" "}
              <b>600 Wh</b>. Maksymalny zasięg to ok. <b>80 km</b> co pozwoli Ci
              na pokonanie np. dystansu 2 maratonów!
            </p>
            <p>Ale dlaczego ktoś miałby to robić, prawda? </p>
          </div>
          <div className="range-row-route-image">
            <GatsbyImage
              image={getImage(data.marathon)}
              alt={data.marathon.name}
            />
          </div>
        </div>
        <div className="range-row-right-image-container">
          <div className="range-row-image">
            <Parallax translateY={["-30px", "20px"]} style={{ zIndex: "3" }}>
              <GatsbyImage
                image={getImage(data.bike)}
                alt={data.bike.name}
                className="range-row-bike-image"
              />
            </Parallax>
            <GatsbyImage
              image={getImage(data.finishline)}
              alt={data.finishline.name}
              className="range-row-finish-line"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RangeRow;
