import React, { useRef } from "react";

import { ParallaxProvider } from "react-scroll-parallax";

import Helmet from "react-helmet";
import Layout from "../common/Layout";
import HazayGangRow from "./HazayGangRow";
import MainRow from "./MainRow";
import PresentationRow from "./PresentationRow";
import RangeRow from "./RangeRow";
import NewsletterRow from "./NewsletterRow";
import ElectrifedRow from "./ElectrifiedRow";
import PayloadRow from "./PayloadRow";
import SEO from "../common/SEO";

import MainRowPreorder from "../preorder/MainRow";

const LandingPage = (props) => {
  const newsletterRef = useRef(null);

  return (
    <ParallaxProvider>
      <Layout>
        <SEO title="Hazay Bikes" />
        <MainRow newsletterRef={newsletterRef} />
        <MainRowPreorder onDifferentPage />
        <PresentationRow />
        <PayloadRow />
        <ElectrifedRow />
        <RangeRow />
        <HazayGangRow />
        <NewsletterRow newsletterRef={newsletterRef} />
      </Layout>
    </ParallaxProvider>
  );
};

export default LandingPage;
