import React from "react";

import Container from "./Container";

import hazayGang from "../../images/hazaygang.svg";

const HazayGangRow = (props) => {
  return (
    <Container
      containerStyle={{ backgroundColor: "white", paddingTop: "8rem" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <img src={hazayGang} alt="Hazay Gang" style={{ width: "250px" }} />
      </div>
    </Container>
  );
};

export default HazayGangRow;
