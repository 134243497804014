import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper";

import Container from "./Container";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

const PresentationRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      second: file(relativePath: { eq: "renderH.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, layout: CONSTRAINED)
        }
      }
      third: file(relativePath: { eq: "renderG.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Container>
      <div>
        <h2>Dopasowany do Twoich potrzeb</h2>
      </div>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{ delay: 2500 }}
        speed={500}
      >
        <SwiperSlide>
          <GatsbyImage image={getImage(data.third)} alt={data.third.name} />
        </SwiperSlide>
        <SwiperSlide>
          <GatsbyImage image={getImage(data.second)} alt={data.second.name} />
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};

export default PresentationRow;
