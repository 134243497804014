import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Container from "./Container";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

import "./LandingPage.css";
import "./ElectrifiedRow.css";

const ElectrifedRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "renderI.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, layout: CONSTRAINED)
        }
      }
    }
  `);

  return (
    <Container
      containerStyle={{ backgroundColor: "white", paddingTop: "0rem" }}
    >
      <div className="details-electified-row-container">
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GatsbyImage image={getImage(data.file)} alt={data.file.name} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <h2 className="electrified-title">
            Silnik 250W <FontAwesomeIcon icon={faBolt} className="h2-icon" />
          </h2>

          <p>
            Sercem Hazaya jest silnik centralny o mocy <b>250W</b>, dzięki
            któremu wjedziesz załadowany pod korek nawet na najbardziej stromy
            podjazd. Do wyboru masz <b>5 trybów wspomagania</b>. Silnik
            uzupełnia klasyczna przerzutka z kasetą, co sprawia, że napęd jest
            prosty i niezawodny.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default ElectrifedRow;
