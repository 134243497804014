import * as React from "react";

import LandingPage from "../components/landingPage/LandingPage";

// markup
const IndexPage = () => {
  return (
    <main>
      <LandingPage />
    </main>
  );
};

export default IndexPage;
