import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { Link } from "gatsby";

import claim from "../../images/claim.svg";

import "./MainRow.css";

const MainRow = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "preorder-theme-dark.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, layout: FULL_WIDTH)
        }
      }
    }
  `);

  const { newsletterRef, onDifferentPage } = props;

  const ctaText = onDifferentPage ? "Dowiedz się więcej" : "Zapytaj o ofertę";
  const ctaLink = onDifferentPage ? "/preorder" : "/preorder/#preorder-contact";

  return (
    <div style={{ display: "grid", backgroundColor: "black" }}>
      <GatsbyImage
        image={getImage(data.file)}
        objectPosition="75% 50%"
        className={`preorder-row-image ${
          onDifferentPage && "preorder-row-image-different-page"
        }`}
        alt={data.file.name}
      />
      <div className="preorder-row-container">
        <div className="landing-page-limiter preorder-row-content">
          <h1
            className={`preorder-title ${
              onDifferentPage && "preorder-title-differtent-page"
            }`}
          >
            Hazay w przedsprzedaży
          </h1>
          <p className="preorder-title">
            Do końca 2022 pierwsze rowery będą gotowe do odebrania. Jeśli jesteś
            zainteresowany naszą ofertą, parametrami roweru, harmonogramem
            dostaw oraz cennikiem zostaw nam swojego maila, lub po prostu
            odezwij się na naszego maila lub telefon. Jesteśmy pewni, że
            dostarczymy Ci Hazaya dopasowanego do Twoich potrzeb.
          </p>
          <p className="preorder-title">
            Już od <b>19 999 zł</b> brutto
          </p>
          <div className="preorder-row-button">
            <Link
              style={{
                padding: "10px 20px",
                cursor: "pointer",
                boxShadow: "none",
                textDecoration: "none",
                color: "white",
              }}
              to={ctaLink}
            >
              {ctaText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainRow;
