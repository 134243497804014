import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWeightHanging } from "@fortawesome/free-solid-svg-icons";

import Container from "./Container";

import "./LandingPage.css";
import "./PayloadRow.css";

const ElectrifedRow = (props) => {
  const data = useStaticQuery(graphql`
    {
      first: file(relativePath: { eq: "renderE.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1250, layout: CONSTRAINED)
        }
      }
      firstMirror: file(relativePath: { eq: "renderE-mirror.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1150, layout: CONSTRAINED)
        }
      }
      second: file(relativePath: { eq: "renderF.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1250, layout: CONSTRAINED)
        }
      }
      secondMirror: file(relativePath: { eq: "renderF-mirror.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1150, layout: CONSTRAINED)
        }
      }
    }
  `);
  return (
    <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <div
        className="landing-page-limiter"
        style={{
          overflow: "hidden",
        }}
      >
        <h2 className="payload-title">
          Ładowność do 100kg{" "}
          <FontAwesomeIcon icon={faWeightHanging} className="h2-icon" />
        </h2>
      </div>
      <Container
        containerStyle={{
          backgroundColor: "#ffd64c",
          paddingTop: "5rem",
          paddingBottom: "5rem",
        }}
      >
        <div className="details-payload-row-container">
          <div style={{ flex: 1 }}>
            <p>
              <b>Dostosowuj</b> przestrzeń cargo do swoich potrzeb.
            </p>
            <p>
              przestrzeń cargo do swoich potrzeb. Możesz w niej bezpiecznie
              wozić to, co najważniejsze - nasz rower jest przystosowany do
              transportu dzieci w wieku od <b> 2 do 6 roku</b>. . A wszystkie
              inne towary możesz zabrać przy okazji - w Hazayu zastosowaliśmy{" "}
              <b>demontowalne pałąki</b>, co sprawia, że możliwe jest niemal
              każdego ładunku - nawet tego najbardziej niestandardowego!
            </p>
          </div>
          <div style={{ position: "relative", flex: 1 }}>
            <div className="details-payload-row-image-container">
              <GatsbyImage
                image={getImage(data.first)}
                className="payload-image-1 payload-image-desktop"
                alt={data.first.name}
              />
              <GatsbyImage
                image={getImage(data.firstMirror)}
                className="payload-image-1 payload-image-mobile"
                alt={data.first.name}
              />

              <GatsbyImage
                image={getImage(data.second)}
                className="payload-image-2 payload-image-desktop"
                alt={data.second.name}
              />
              <GatsbyImage
                image={getImage(data.secondMirror)}
                className="payload-image-2 payload-image-mobile"
                alt={data.second.name}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ElectrifedRow;
